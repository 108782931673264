<template>
  <v-row class="inner-content">
    <v-col>

      <v-card class="pt-2">
        <v-card-title>
          <span>Account</span>

          <v-spacer></v-spacer>

          <GoogleAccountAutoComplete
            v-model="selectedAccountID"
            @changed="accountChanged"
            @refreshing="accountsRefreshing"
          ></GoogleAccountAutoComplete>

          <v-spacer></v-spacer>

          <v-autocomplete v-model="selectedLanguageID" :items="languages" :loading="loadingRefreshLanguages" item-text="language_iso2" item-value="languages_fk" label="Language"
            prepend-icon="language">
          </v-autocomplete>

          <v-spacer></v-spacer>

        </v-card-title>

        <KeywordCollectorTable :pAccountID="selectedAccountID" :pVerticalID="verticalID" :pCountryID="countryID" :pLanguageID="selectedLanguageID" :updateKey="updateTableKey"></KeywordCollectorTable>
      </v-card>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'KeywordCollectorFindNewKeywords',

  data () {
    return {
      loadingRefreshLanguages: false,
      lsSelectedAccount: 'keywordCollector_selectedAccount',
      selectedAccountID: 0,
      vertical: '',
      verticalID: 0,
      countryISO2: '',
      countryID: 0,
      languages: [],
      selectedLanguageID: 0,
      updateTableKey: 'init',
    }
  },

  computed: {
    accountLanguagesEndpoint () {
      return '/a/core/account-languages?ad_network=Google&xfields=languages_fk,language_iso2&xsort=language_iso2&xper_page=5000&account_id=' + this.selectedAccountID
    },

    accountMetadataEndpoint () {
      return '/a/core/account-metadata?ad_network=Google&account_id=' + this.selectedAccountID
    },
  },

  components: {
    GoogleAccountAutoComplete: () => import('@/components/autocompletes/GoogleAccountAutoComplete.vue'),
    KeywordCollectorTable: () => import('@/components/tables/KeywordCollectorTable.vue')
  },

  methods: {
    accountChanged: function () {
      if (!this.selectedAccountID) {
        return
      }

      // save to local storage
      localStorage.setItem(this.lsSelectedAccount, JSON.stringify(this.selectedAccountID))

      // reset account metadata
      this.vertical = ''
      this.verticalID = 0
      this.countryISO2 = ''
      this.countryID = 0
      this.selectedLanguageID = 0
      this.getAccountLanguages()
      this.getAccountMetadata()
    },

    accountsRefreshing () {
      this.selectedAccountID = 0
      this.selectedLanguageID = 0
      this.languages = []
    },

    getAccountLanguages () {
      this.loadingRefreshLanguages = true

      this.$http.get(this.accountLanguagesEndpoint).then(resp => {
        if (resp.data.data) {
          this.languages = resp.data.data
        } else {
          this.languages = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: this.accountLanguagesEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loadingRefreshLanguages = false)
    },

    getAccountMetadata () {
      this.$http.get(this.accountMetadataEndpoint).then(resp => {
        if (resp.data.data.length == 1) {
          var metaData = resp.data.data[0]
          this.vertical = metaData.vertical
          this.verticalID = metaData.verticals_fk
          this.countryISO2 = metaData.country_iso2
          this.countryID = metaData.countries_fk
          this.selectedLanguageID = metaData.languages_fk
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: this.accountMetadataEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally()
    },
  },

  created: function () {

    // if present, use local selected account
    if (localStorage.getItem(this.lsSelectedAccount)) {
      this.selectedAccountID = JSON.parse(localStorage.getItem(this.lsSelectedAccount))
      this.getAccountLanguages()
      this.getAccountMetadata()
    }
  },
}
</script>
